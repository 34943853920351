<template>
	<div>
		<pui-datatable :modelName="modelName" :actions="actions" :modelColumnDefs="modelColumnDefs"></pui-datatable>
		<visitmodals :modelName="modelName"></visitmodals>
	</div>
</template>

<script>
import VisitModals from './VisitModals.vue';
import VisitActions from './VisitActions';

export default {
	name: 'visit-grid',
	components: {
		components: { visitmodals: VisitModals }
	},
	data() {
		return {
			modelName: 'visit',
			actions: VisitActions.gridactions,
			stopDescription: '',
			modelColumnDefs: {
				status: {
					render: (data, type, row) => {
						if (row.statusoutlinecolor && row.statuscolor) {
							return `<label style="color: white; background-color: ${row.statuscolor}; border: 2px solid ${row.statusoutlinecolor}; font-weight: bold; padding: 3px; border-radius: 10px">${data}</label>`;
						} else {
							return data;
						}
					}
				},
				notifications: {
					render: (data, type, row) => {
						console.log(row.stopcount);
						let notification = '';

						if (row.chatcount > 0) {
							let chatDescription = this.$t('header.visit.chatNotification');
							notification += `<i title='${chatDescription}' style="color: red; padding-right: 10px" class="fak fa-solid-coment"></i>`;
						}
						if (row.stopcount == 0) {
							this.stopDescription = this.$t('header.visit.noStopNotification');
							notification += `<i title='${this.stopDescription}' style="color: red" class="fak fa-notification-no-berthing"></i>`;
						}

						return notification;
					}
				}
			}
		};
	},
	computed: {},
	methods: {},
	mounted() {}
};
</script>
