const alertSubscription = {
	id: 'alertSubscription',
	selectionType: 'single', // [single|multiple|general]
	label: 'actions.visit.alertSubscription',
	functionality: 'CREATE_SUBSCRIPTION', // set the functionality if needed
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model, registries) {
		// Sample code to open a model dialog
		const row = registries[0];
		// Get PK for the header
		/*const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;*/
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-alertSubscription-' + model.name + '-show', row);
	}
};

const visitTransitions = {
	id: 'visitTransitions',
	selectionType: 'single', // [single|multiple|general]
	label: 'actions.visit.transitions',
	functionality: 'ACTION_VISIT_STOP_TRANSITIONS', // set the functionality if needed
	checkAvailability: function () {
		return true;
	},
	runAction: function (action, model, registries) {
		// Sample code to open a model dialog
		const row = registries[0];
		// Get PK for the header
		/*const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;*/
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-visitTransitions-' + model.name + '-show', row);
	}
};

export default {
	gridactions: [alertSubscription, visitTransitions],
	formactions: [visitTransitions]
};
